<template>
  <div>
    <div class="errorLayout">
      <div class="errorLayoutSplitter">
        <img src="@/assets/icons/errorLatest.png" alt="" class="errorPoster" />
        <p class="errorText">Error 404 !</p>
        <p class="errorDescription">
          We cant find the page you are looking for.
        </p>
        <button class="error-return" @click="goToHome()">Return To Home</button>
      </div>

      <!-- <p class="no-contents">{{$t("No Contents Found")}}</p> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "ErrorPage",
  data() {
    return {};
  },
  methods:{
    goToHome(){
      console.log('go to home----------------')
      this.$router.push({ name: "Home" });
    }
  }
};
</script>
<style lang="scss">
@import "@/sass/_variables.scss";
@import "@/sass/_components.scss";

.errorLayout {
  width: 800px;
  height: 400px;
  margin: 100px auto;
  background-color: black;
  .errorLayoutSplitter {
    display: flex;
    flex-direction: column;
    align-items: center;
    .errorPoster {
      margin-top: 40px;
    }
    .errorText {
      margin-top: 16px;
      font-size: 24px;
      color: #ffffff;
      font-weight: bold;
      margin-right: 30px;
      font-family: $font-regular;
    }
    .errorDescription {
      margin-top: 16px;
      font-size: 16px;
      color: #848486;
      font-family: $font-regular;
      margin-right: 30px;
    }
    .error-return {
      margin-right: 30px;
      margin-top: 20px;
      width: 150px;
      height: 36px;
      border-radius: 4px;
      border: 1px solid $btn-clr;
      background: none;
      color: $btn-clr;
      opacity: 1;
      outline: none;
      font-family: $font-regular;
      cursor: pointer;
    }
  }
}

// .no-contents {
//   text-align: center;
//   font-size: 28px;
//   color: white;
//   font-family: "Open Sans", sans-serif;
// }
@media only screen and (max-width: 576px) {
  .errorLayout {
  width: 400px;
  height: 400px;
  margin: 100px auto;
  background-color: black;
  }
}

@media only screen and (min-width: 577px) and (max-width: 768px) {
  .errorLayout{
    margin-top: 300px;
  }
}


</style>
